import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Particle from "../Particle";
import laptopImg from "../../Assets/about.png";

import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiCss3,
  DiPython,
  DiHtml5,
  DiJava,
} from "react-icons/di";
import {
  SiVisualstudiocode,
  SiSlack,
  SiVercel,
} from "react-icons/si";
import { ImPointRight } from "react-icons/im";

function About() {
  return (
    <Container fluid className="about-section" id="about">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Learn Who <strong className="purple">I AM</strong>
            </h1>
            <Card className="quote-card-view">
              <Card.Body>
                <blockquote className="blockquote mb-0">
                  <p style={{ textAlign: "justify" }}>
                    Hello, I am{" "}
                    <span className="purple">Mustafa Ipekci </span> from{" "}
                    <span className="purple"> Istanbul, Turkey.</span>
                    <br />
                    I am a high school student entrenched in the world of software.
                    <br />
                    I have dedicated my academic life into learning and honing my development skills.
                    <br />
                    <br />
                    Apart from coding, I also love to:
                  </p>
                  <ul>
                    <li className="about-activity">
                      <ImPointRight /> Woodshop
                    </li>
                    <li className="about-activity">
                      <ImPointRight /> Working out regularly
                    </li>
                    <li className="about-activity">
                      <ImPointRight /> Playing video games
                    </li>
                  </ul>

                  <p style={{ color: "rgb(155 126 172)" }}>
                    "When you can't run you walk, and when you can't walk you will crawl"{" "}
                  </p>
                </blockquote>
              </Card.Body>
            </Card>
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>
        </Row>
        <h1 className="project-heading">
          Professional <strong className="purple">Skillset </strong>
        </h1>

        <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
          <Col xs={4} md={2} className="tech-icons">
            <DiJavascript1 />
          </Col>
          <Col xs={4} md={2} className="tech-icons">
            <DiNodejs />
          </Col>
          <Col xs={4} md={2} className="tech-icons">
            <DiReact />
          </Col>
          <Col xs={4} md={2} className="tech-icons">
            <DiPython />
          </Col>
          <Col xs={4} md={2} className="tech-icons">
            <DiJava />
          </Col>
          <Col xs={4} md={2} className="tech-icons">
            <DiCss3 />
          </Col>
          <Col xs={4} md={2} className="tech-icons">
            <DiHtml5 />
          </Col>
        </Row>

        <h1 className="project-heading">
          <strong className="purple">Tools</strong> I use
        </h1>

        <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
          <Col xs={4} md={2} className="tech-icons">
            <SiVisualstudiocode />
          </Col>
          <Col xs={4} md={2} className="tech-icons">
            <SiSlack />
          </Col>
          <Col xs={4} md={2} className="tech-icons">
            <SiVercel />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default About;
