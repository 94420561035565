import React from "react";
import Particles from "react-tsparticles";

function Particle() {
  return (
    <Particles
      id="tsparticles"
      params={{
        particles: {
          number: {
            value: 50,
            density: {
              enable: false,
            },
          },
          line_linked: {
            enable: false,
          },
          move: {
            enable: false,
          },
          size: {
            value: 1,
          },
          opacity: {
            value: 0.7,
            anim: {
              enable: true,
              speed: 2,
              opacity_min: 0.1,
              sync: false,
            },
          },
        },
      }}
    />
  );
}

export default Particle;
