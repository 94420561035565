import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import { CgWebsite } from "react-icons/cg";
import lmaoKeyboardImg from "../../Assets/Projects/lmao-keyboard.jpg";
import firstWebsiteImg from "../../Assets/Projects/first-website.jpg";
import dramaClubWebsiteImg from "../../Assets/Projects/drama-club-website.jpg";

function Project() {
  return (
    <Container fluid className="project-section" id="projects">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <Card className="project-card-view">
              <Card.Img variant="top" src={lmaoKeyboardImg} alt="LMAO Keyboard" />
              <Card.Body>
                <Card.Title>LMAO Keyboard</Card.Title>
                <Card.Text style={{ textAlign: "justify" }}>
                  This is a simple spline project, controlled by your own keyboard
                </Card.Text>
                <Button
                  variant="primary"
                  href="https://app.spline.design/community/file/147ec1c4-bb07-41c9-9769-e1964b411463"
                  target="_blank"
                >
                  <CgWebsite /> &nbsp; Play around!
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} className="project-card">
            <Card className="project-card-view">
              <Card.Img variant="top" src={firstWebsiteImg} alt="First Website" />
              <Card.Body>
                <Card.Title>My First Website</Card.Title>
                <Card.Text style={{ textAlign: "justify" }}>
                  This was one of my first websites, and it was a placeholder for this one you're exploring now!
                </Card.Text>
                <Button
                  variant="primary"
                  href="https://mustafa-ipekci-portfolio.framer.ai/"
                  target="_blank"
                >
                  <CgWebsite /> &nbsp; Demo
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} className="project-card">
            <Card className="project-card-view">
              <Card.Img variant="top" src={dramaClubWebsiteImg} alt="Drama Club Website" />
              <Card.Body>
                <Card.Title>Drama Club Website</Card.Title>
                <Card.Text style={{ textAlign: "justify" }}>
                  This is a website I worked on for my schools Drama club during my time in the Web Development club
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Project;
