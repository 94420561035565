import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Particle from '../Particle';
import Spline from '@splinetool/react-spline';
import myImg from '../../Assets/avatar.svg';
import Tilt from 'react-parallax-tilt';
import { AiFillGithub, AiFillFacebook } from 'react-icons/ai';
import { FaLinkedinIn } from 'react-icons/fa';
import '../../style.css';

function Home() {
  const [showSecondTitle, setShowSecondTitle] = useState(false);

  useEffect(() => {
    // Set a timer to show the second title after 2 seconds
    const timer = setTimeout(() => {
      setShowSecondTitle(true);
    }, 1000);

    // Clear the timer on component unmount to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);

  return (
    <section id="home">
      {/* Second title that stays at the bottom */}
      {showSecondTitle && (
        <div className="dev-title" style={{ padding: '50px', textAlign: 'center', zIndex: '998', position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}>
          <h1 className="purple">The Software Developer</h1>
        </div>
      )}

      <div className="spline-section" id="spline" style={{ height: '750px', position: 'relative', zIndex: '999' }}>
        <Spline scene="https://prod.spline.design/HKlfAoUoTv3w35yQ/scene.splinecode" />
      </div>
      <Particle />
      <Container fluid className="home-about-section" id="about">
        <Container>
          <Row>
            <Col md={12} className="home-about-description">
              <p className="home-about-body">
                I've loved the concept of creation for a long time programming has let me grow my passion
                <br />
                <br />I am fluent in the basics like
                <i>
                  <b className="purple"> Css, Javascript and Python. </b>
                </i>
                <br />
                <br />
                My interests can vary between &nbsp;
                <i>
                  <b className="purple">Web Technologies and design</b> and
                  also {' '}
                  <b className="purple">
                    AI implementation.
                  </b>
                </i>
                <br />
                <br />
                I also create and apply my knowledge to newer resources
                like <b className="purple">Spline Design</b> and
                <i>
                  <b className="purple">
                    {' '}
                    the basics of Javascript and other frameworks
                  </b>
                </i>
                &nbsp; like
                <i>
                  <b className="purple"> working with React.js</b>
                </i>
              </p>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={4} className="myAvtar">
              <Tilt>
                <img src={myImg} className="img-fluid" alt="avatar" />
              </Tilt>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="home-about-social">
              <h1>FIND ME</h1>
              <p>
                Easily <span className="purple">connect </span>with me through
              </p>
              <ul className="home-about-social-links">
                <li className="social-icons">
                  <a
                    href="https://github.com/M-Ipekci"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour  home-social-icons"
                  >
                    <AiFillGithub />
                  </a>
                </li>
                <li className="social-icons">
                  <a
                    href="https://www.linkedin.com/in/mustafa-ipekci-96a98925b/"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour  home-social-icons"
                  >
                    <FaLinkedinIn />
                  </a>
                </li>
                <li className="social-icons">
                  <a
                    href="https://www.facebook.com/mufasa.ipek"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour home-social-icons"
                  >
                    <AiFillFacebook />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Home;
