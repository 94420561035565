// App.js
import React, { useState, useEffect } from 'react';
import Preloader from './components/Pre';
import Navbar from './components/Navbar';
import Home from './components/Home/Home';
import About from './components/About/About';
import Projects from './components/Projects/Projects';
import Resume from './components/Resume/ResumeNew';
import Footer from './components/Footer';
import './style.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [load, updateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      updateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App" id={load ? 'no-scroll' : 'scroll'}>
      <Preloader load={load} />
      <Navbar />
      <Home id="home" />
      <About id="about" />
      <Projects id="projects" />
      <Resume id="resume" />
      <Footer />
    </div>
  );
}

export default App;
